<template>
  <div class="pt-4 w-100 containerLabsPage">
    <custom-scrollbar>
      <b-container
        fluid
      >
        <div class="h3 mb-4">
          Юр.лица
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <b-form-checkbox
            v-model="isActive"
            size="sm"
            @input="fetchLegalPartners()"
          >
            <span class="text-secondary">
              Активирован
            </span>
          </b-form-checkbox>
          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            @click="openAddLegalPartnerModal"
          >
            Добавить Юр.лицо
          </b-button>
        </div>
        <div class="mt-4 mb-4 d-flex">
          <b-input-group>
            <b-form-input
              v-model="legalPartnerName"
              placeholder="По названию юр. лица"
              @keyup.native.enter="fetchLegalPartners"
            />
            <b-form-input
              v-model="legalPartnerInn"
              placeholder="По инн"
              @keyup.native.enter="fetchLegalPartners"
            />
          </b-input-group>

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="ml-2"
            @click="fetchLegalPartners"
          >
            <b-icon icon="search" />
          </b-button>
        </div>
        <div class="partnerSelect">
          <v-select
            v-model="selectedPartnerId"
            :reduce="partner => partner.id"
            :options="optionsPartner"
            placeholder="Выберите Партнера"
            label="name"
            @input="fetchLegalPartners"
          />
        </div>
        <b-overlay
          v-if="!legalPartnerListStore || Object.keys(legalPartnerListStore).length === 0"
          :show="isLoading"
          variant="transparent"
          no-wrap
        />
        <div v-if="legalPartnerListStore">
          <div v-if="Object.keys(legalPartnerListStore).length !== 0">
            <b-table
              v-if="legalPartnerListStore"
              :fields="fields"
              :items="legalPartnerListStore.data"
              :busy="isLoading"
              :per-page="0"
              :current-page="currentPage"
              :filter="filter"
              class="bg-white crm-table mt-4"
              bordered
              hover
              show-empty
              :small="true"
            >
              <template #empty="">
                <div class="d-flex justify-content-center align-items-center">
                  <h4 class="p-2">
                    Не найдено <b-icon
                      icon="exclamation-triangle-fill"
                      variant="danger"
                    />
                  </h4>
                </div>
              </template>
              <template v-slot:cell(isActive)="row">
                <b-badge
                  v-if="row.item.isActive"
                  class="p-2"
                  pill
                  variant="success"
                >
                  Активирован
                </b-badge>
                <b-badge
                  v-else
                  class="p-2"
                  pill
                  variant="danger"
                >
                  Не активирован
                </b-badge>
              </template>
              <template #cell(actions)="row">
                <div class="d-flex">
                  <b-button
                    v-b-tooltip.hover
                    class="contact-legal-delete mr-2 ml-2"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    title="Редактировать"
                    @click="openEditPartnerModal(row)"
                  >
                    <b-icon
                      icon="pencil-fill"
                    />
                  </b-button>
                  <b-button
                    v-if="row.item.isActive"
                    v-b-tooltip.hover
                    class="contact-legal-delete mr-2"
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    title="Деактивировать"
                    @click="onClickDeActivateLegalPartner(row)"
                  >
                    <b-icon icon="person-x-fill" />
                  </b-button>
                  <b-button
                    v-else
                    v-b-tooltip.hover
                    class="contact-legal-delete mr-2"
                    variant="success"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    title="Активировать"
                    @click="onClickActivateLegalPartner(row)"
                  >
                    <b-icon icon="person-check-fill" />
                  </b-button>
                </div>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2" />
                  <strong>Загрузка...</strong>
                </div>
              </template>
            </b-table>
            <div
              class="mt-3"
            >
              <div
                v-show="legalPartnerListStore"
                class="crm-pagination my-4"
              >
                <b-dropdown
                  id="dropdown-1"
                  :text="`${this.perPage}`"
                  split
                  class="mr-2"
                  style="height: 34px"
                  size="sm"
                  split-variant="outline-primary"
                  variant="primary"
                >
                  <b-dropdown-item @click="tableSizeHandler(10)">
                    10
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(25)">
                    25
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(50)">
                    50
                  </b-dropdown-item>
                </b-dropdown>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="+allCount"
                  :per-page="perPage"
                  class="mb-0"
                  @input="fetchLegalPartners"
                />
              </div>
            </div>
          </div>
          <span v-else>Ничего не найдено</span>
        </div>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'LaboratoryLegalPartners',
  page: {
    title: 'CRM Doctis - Юр.лица',
  },
  components: {},
  data() {
    return {
      fields: [
        {
          key: 'legalName',
          label: 'Название Юр.лица',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'legalName',
          label: 'Название Юр.лица',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'partner.name',
          label: 'Партнер',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'address',
          label: 'Адрес',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'inn',
          label: 'ИНН',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'kpp',
          label: 'КПП',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'license',
          label: 'Лицензия',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'isActive',
          label: 'Статус Юр.лица',
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-middle',
          class: ['actionsLegalPartnerClass', 'text-center'],
        },
      ],
      userData: null,
      currentPage: 1,
      perPage: 10,
      filter: null,
      rows: 1,
      partnerList: [],
      isActive: true,
      allCount: null,
      legalPartnerName: null,
      legalPartnerInn: null,
      optionsPartner: [],
      selectedPartnerId: '',
      limit: 10,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: types.PARTNER_IS_LOADING_GET,
    }),
    partnerListStore() {
      return this.$store.state.Laboratories.partnerList;
    },
    legalPartnerListStore() {
      return this.$store.state.Laboratories.legalPartnerList;
    },
    paginationParams() {
      return {
        LegalName: this.legalPartnerName,
        Inn: this.legalPartnerInn,
        limit: this.limit,
        offSet: this.offSet,
        isActive: this.isActive,
        PartnerId: this.selectedPartnerId,
      };
    },
    offSet() {
      return (this.currentPage - 1) * this.limit;
    },
  },
  async created() {
    await this.fetchLegalPartners();
    await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNERS, {});
    this.optionsPartner = this.partnerListStore.data;
  },
  beforeDestroy() {
    this.$store.commit(this.$types.LEGAL_PARTNERS_SET, null);
  },
  methods: {
    async tableSizeHandler(mount) {
      this.perPage = mount;
      this.limit = this.perPage;
      await this.fetchLegalPartners();
    },
    async fetchLegalPartners() {
      const data = await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, this.paginationParams);
      this.allCount = data.xTotalCount;
    },
    async onClickActivateLegalPartner(row) {
      await this.$store.dispatch(this.$types.LABORATORY_LEGAL_PARTNER_ACTIVATE, row.item.id);
      await this.fetchLegalPartners();
    },
    async onClickDeActivateLegalPartner(row) {
      await this.$store.dispatch(this.$types.LABORATORY_LEGAL_PARTNER_DEACTIVATE, row.item.id);
      await this.fetchLegalPartners();
    },
    openAddLegalPartnerModal() {
      uiService.showModal(MODALS.ADD_LEGAL_PARTNER_MODAL, {
        name: 'AddLegalPartnerModal',
        props: {
          params: this.paginationParams,
        },
      });
    },
    openEditPartnerModal(row) {
      uiService.showModal(MODALS.EDIT_LEGAL_PARTNER_MODAL, {
        name: 'EditLegalPartnerModal',
        props: {
          legalPartnerId: row.item.id,
          params: this.paginationParams,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-list-content {
  height: calc(100vh - #{$header-height});
  transition: height 0.3s linear;
}

::v-deep.crm-table {
  overflow: hidden;

  .actionsLegalPartnerClass{
    min-width: 6.1rem !important;
  }
}

.crm-pagination {
  display: flex;
  align-items: center;
}

.partnerSelect{
  background-color: white;
}
</style>
